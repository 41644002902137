import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";

function About(){
return(
    <>
     <Innernavbar />
     <section className="our_servces">
  <div className="container">





  </div>
</section>


<section class="about-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="section-heading text-uppercase">About Us</h2>
                    <p class="text-muted">Welcome to Reliep, your ultimate destination for hassle-free service bookings across India. At Reliep, we strive to simplify your life by connecting you with reliable service providers for a wide range of needs, right at your fingertips. Our vision is to become India's leading online platform for service bookings, providing a seamless experience for users and service providers alike. We aim to revolutionize how services are accessed and delivered across the country.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="ceo-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <img class="mx-auto rounded-circle" src="/assets/images/rakesh.jpeg" alt="CEO Image" />
                    <h2 class="section-heading text-uppercase">Meet Our CEO</h2>
                    <h3>Rakesh Bali</h3>
                    <p class="text-muted" style={{"textAlign":"justify"}}>Rakesh Bali brings a wealth of experience and a visionary approach to Reliep. With a career spanning over 10 years in the technology and ecommerce sectors, Rakesh has played a pivotal role in shaping the company's growth and strategic direction.</p>
                    <p class="text-muted" style={{"textAlign":"justify"}}>Rakesh's journey in the technology industry began with a passion for innovation and a deep-seated belief in the transformative power of ecommerce. His early career saw him [briefly outline early career highlights. Rakesh is renowned for his visionary approach to business, constantly pushing boundaries and exploring new avenues for growth and customer satisfaction. His commitment to innovation has led Reliep to introduce cutting-edge technologies and user-centric features that enhance the overall service experience. Rakesh believes in building strong relationships with customers, partners, and employees alike. His leadership philosophy revolves around transparency, integrity, and a relentless pursuit of excellence. He fosters a culture of innovation and collaboration within Reliep, empowering team members to contribute to the company's success. </p>
                </div>
            </div>
        </div>
    </section>

    {/* <section class="team-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="section-heading text-uppercase">Our Team</h2>
                    <p class="text-muted">Meet the amazing people who contribute to our success.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="https://via.placeholder.com/200" alt="" />
                        <h3>John Doe</h3>
                        <p class="text-muted">Lead Developer</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="https://via.placeholder.com/200" alt="" />
                        <h3>Jane Smith</h3>
                        <p class="text-muted">Designer</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="https://via.placeholder.com/200" alt="" />
                        <h3>Mike Johnson</h3>
                        <p class="text-muted">Marketing</p>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
 <Footer />
    </>
)
}
export default About;